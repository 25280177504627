import React, { Fragment, useEffect, useState } from "react";
import "../../css/coordinator.css";
import { NavLink } from "react-router-dom";

// API
import {
  useGetAllCoordinatorQuery,
  useDeleteCoordinatorMutation,
} from "../../handler/coordinator.api";

// TOAST LIBRARY
import { SuccessToast, ToastError } from "../Toast/Notification";
import { ToastContainer } from "react-toastify";

export default function Coordinator() {
  const [buttonIndex, setButtonIndex] = useState(null);

  // API INITIALIZATION
  const getAllCoordinator = useGetAllCoordinatorQuery();
  const [deleteCoordinatorApi, { isLoading, isSuccess, isError, error }] =
    useDeleteCoordinatorMutation();
  // pagi 
    // console.log(getAllSponsor.data?.data)
    const data = getAllCoordinator.data?.data;
  
      const itemsPerPage = 10; // Show 10 items per page
      const [currentPage, setCurrentPage] = useState(1);
    
      // Calculate total pages
      const totalPages = Math.ceil(data?.length / itemsPerPage);
    
      // Get current page data
      const paginatedData = data?.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      );
  const [clicked, setClicked] = useState(false);

  const handleactionClick = (id) => {
    setClicked(!clicked);
    setButtonIndex(id);
  };

  // DELETE COORDINATOR
  async function deleteCoordinatorHandler(id) {
    await deleteCoordinatorApi(id);
  }

  // SUCCESSFULLY DELETE MESSAGE
  useEffect(() => {
    if (isSuccess) {
      SuccessToast("Successfully deleted");
    }
    if (isError) {
      ToastError(error?.data?.message);
    }
  }, [isSuccess, isError]);

  return (
    <>
      <div className="cd-container">
        <div className="btn-add-new text-uppercase">
          <NavLink to="/addcoordinator">
            Add new <i className="cd-plus fa-solid fa-plus"></i>
          </NavLink>
        </div>
        {getAllCoordinator.isLoading && <div className="bigSpinner"></div>}
        <div className="cd-main-container">
          <table className="col cd-list-title">
            <thead>
              <tr>
                <th className="cd-code color">Code</th>
                <th className="cd-name color">Name</th>
                <th className="cd-address color">Address</th>
                <th className="cd-country color">Country</th>
                <th className="cd-email color">Email</th>
                <th className="cd-action color">Action</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData?.map((dataItem, index) => {
                let address = dataItem?.address || "";
                let country = dataItem?.country || "";
                let trimAddress = address.substring(0, 10);
                let trimCountry = country.substring(0, 10);
                return (
                <Fragment key={dataItem?._id}>
                  <tr className="coordinator-table-list-container">
                    <td className="cd-code">{dataItem?.code}</td>
                    <td className="cd-name fw-bolder">{dataItem?.name}</td>
                    <td className="cd-address">{ trimAddress.length >= 10
                            ? `${trimAddress}...`
                            : trimAddress}</td>
                    <td className="cd-country">{ trimCountry.length >= 10
                            ? `${trimCountry}...`
                            : trimCountry}</td>
                    <td className="text-lowercase cd-email">
                      {dataItem?.email || 0}
                    </td>
                    <td
                      className="text-center fs-4 cd-action cdaction-btn"
                      id="ibtn"
                      onClick={() => handleactionClick(index)}
                    >
                      <i className="fa-solid fa-circle-info mx-auto"></i>
                    </td>
                    <td
                      className="cd-action action-btn text-end"
                      id="actionbtn"
                    >
                      <div className="action-edit">
                        <NavLink to={`/coordinate/edit/${dataItem?._id}`}>
                          <i className="fa-regular fa-pen-to-square"></i>
                        </NavLink>
                      </div>

                      <div
                        className="pi-delete"
                        onClick={() => deleteCoordinatorHandler(dataItem?._id)}
                      >
                        {isLoading ? (
                          <span className="spinnerloader acd-spinner"></span>
                        ) : (
                          <i className="fa-solid fa-trash"></i>
                        )}
                      </div>
                    </td>
                  </tr>
                  {clicked && buttonIndex === index && (
                    <Fragment>
                      <tr className="cdlist-md position-relative">
                        <td colSpan="6">
                          <div className="md-email">
                            <div className="md-name color">Email</div>
                            <div>:</div>
                            <div className="mdname-i text-lowercase">
                              {dataItem?.email || 0}
                            </div>
                          </div>
                          <div className="md-address">
                            <div className="md-name color">Address</div>
                            <div>:</div>
                              <div className="mdname-i">{ trimAddress.length >= 10
                            ? `${trimAddress}...`
                            : trimAddress}</div>
                          </div>
                          <div className="md-country">
                            <div className="md-name color">Country</div>
                            <div>:</div>
                            <div className="mdname-i">{ trimCountry.length >= 10
                            ? `${trimCountry}...`
                            : trimCountry}</div>
                          </div>
                        </td>
                      </tr>
                      <tr className="cdlist-md action-btn text-end">
                        <td colSpan="6" className="d-flex">
                          <div className="action-edit ">
                            <NavLink to={`/coordinate/edit/${dataItem?._id}`}>
                              <i className="fa-regular fa-pen-to-square"></i>
                            </NavLink>
                          </div>
                          <div
                            className="pi-delete"
                            onClick={() =>
                              deleteCoordinatorHandler(dataItem?._id)
                            }
                          >
                            {isLoading ? (
                              <span className="spinnerloader acd-spinner"></span>
                            ) : (
                              <i className="fa-solid fa-trash"></i>
                            )}
                          </div>
                        </td>
                      </tr>
                    </Fragment>
                  )}
                </Fragment>
              )})}
            </tbody>
          </table>
        
        </div>
          {/* Pagination Controls */}
         <div className="pagiControl">
                  <button className="btn btn-outline-primary"
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage((prev) => prev - 1)}
                  >
                    Prev
                  </button>

                  <span> Page {currentPage} of {totalPages} </span>

              <button className="btn btn-outline-primary"
                        disabled={currentPage === totalPages}
                        onClick={() => setCurrentPage((prev) => prev + 1)}
              > Next </button>
        </div>
      </div>
      {/* TOAST CONTAINER */}
      <ToastContainer />
    </>
  );
}
