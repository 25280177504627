import baseApi from "./base.api";

const pujaOrder = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    AddOrder: builder.mutation({
      query: (payload) => ({
        url: "/orderconfirm",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["order", "puja_selected"],
    }),
    DeleteOrder: builder.mutation({
      query: (id) => ({
        url: `/order/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["order"],
    }),
    GetSponsorOrderList: builder.query({
      query: (id) => `sponsorOrderList/${id}`,
      providesTags: ["order"],
    }),
    GetAllOrder: builder.query({
      query: (query) => `/order/${query}`,
      providesTags: ["order"],
    }),
    EditPayment: builder.mutation({
      query: ({ id, status }) => ({
        url: `order/payment/status/${id}`,
        method: "POST",
        body: { status: status },      
      }),invalidatesTags: ["order"],
    })
  }),
});

export const {
  useDeleteOrderMutation,
  useAddOrderMutation,
  useGetSponsorOrderListQuery,
  useGetAllOrderQuery,
  useEditPaymentMutation,
} = pujaOrder;
