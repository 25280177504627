import React, { Fragment, useEffect, useState } from "react";
import "../../css/coordinator.css";
import "../../css/sponserlist.css";
import { NavLink } from "react-router-dom";

import DebounceHook from "../hooks/DebounceHook";
// API
import {
  useGetAllSponsorQuery,
  useDeleteSponsorMutation,
} from "../../handler/sponser.api";

// TOAST LIBARARY
import { SuccessToast, ToastError } from "../Toast/Notification";
import { ToastContainer } from "react-toastify";
import { useSearchParams } from "react-router-dom";
// CHILD COMPONENT ----------------
import PaginateComponent from "../filter/PaginateComponent";
import SearchBarComponent from "../filter/SearchBarComponent";
// framer motion
import { AnimatePresence, motion } from "framer-motion";
import FilterCoordinatorComponent from "../filter/FilterCoordinatorComponent";
import FilterCountry from "../filter/FilterCountry";
import FilterDate from "../filter/FilterDate";
import SponsorDeleteComponent from "../SponorDeleteComponent";
export default function SponserDetail() {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const searchDebounceValue = DebounceHook(search);
  const [coordinatorCode, setCoordinatorCode] = useState("");
  const [country, setCountry] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");

  let query = `page=${page}&limit=14&search=${searchDebounceValue}&country=${country}&coordinator=${coordinatorCode}&year=${year}&month=${month}`;

  // API INTIALIZATION
  const getAllSponsor = useGetAllSponsorQuery(query);
  const [deleteSponsorApi] = useDeleteSponsorMutation();

  // const totalPages = getAllSponsor.data?.numberOffPages;
  const totallist = getAllSponsor.data?.length;
  const data = getAllSponsor.data?.data;

    const itemsPerPage = 10; // Show 10 items per page
    const [currentPage, setCurrentPage] = useState(1);
  
    // Calculate total pages
    const totalPages = Math.ceil(data?.length / itemsPerPage);
  
    // Get current page data
    const paginatedData = data?.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
  

  const [buttonIndex, setButtonIndex] = useState(null);

  const [clicked, setClicked] = useState(false);

  const handleactionClick = (id) => {
    setClicked(!clicked);
    setButtonIndex(id);
  };

  const [show, setShow] = useState(false);
  const barClick = () => {
    setShow(true);
  };
  const xbtn = () => {
    setShow(false);
    console.log(show);
  };
  useEffect(() => {}, [show]);

  useEffect(() => {
    setPage(1);
  }, [search, coordinatorCode, country, year]);

  useEffect(() => {
    if (getAllSponsor?.isError) {
      console.log(getAllSponsor?.error);
      ToastError(getAllSponsor?.error?.error);
      ToastError(getAllSponsor?.error?.data?.message);
    }
  }, [getAllSponsor?.isError]);
  return (
    <>
      {/* sponsor----------------------------- */}
      <div className="sponsor-filter-section">
        <SearchBarComponent setSearch={setSearch} setPage={setPage} />
        <FilterCoordinatorComponent setCoordinatorCode={setCoordinatorCode} />
        <FilterCountry setCountry={setCountry} />
        <FilterDate setYear={setYear} setMonth={setMonth} />
      </div>
      {/* ----------------------------------------- */}
      <div className="cd-container">
      {getAllSponsor.isLoading && <div className="bigSpinner"></div>}

        <div className="sponsor-detail-metadata-list">
          <div>
            <h1>
              <span>Total List</span>
              {totallist}
            </h1>
            <h1>
              <span>Pages</span>
              {page}/{totalPages}
            </h1>
          </div>

          <div>
            <NavLink to="/spform">
              Add new <i className="cd-plus fa-solid fa-plus"></i>
            </NavLink>
          </div>
        </div>

        <div className="cd-main-container">
          <table className="col cd-list-title">
            <thead>
              <tr>
                <th className="cd-code color">Code</th>
                <th className="cd-code color">Date</th>
                <th className="cd-name color">Name</th>
                {/* <th className="cd-address color">address</th> */}
                <th className="cd-email color">Email</th>
                <th className="cd-country color">Country</th>
                <th className="cd-action color">Action</th>
              </tr>
            </thead>

          
              <tbody className="coordinator-table-list-container">
                {paginatedData?.map((dataItem, index) => (
                  <tr key={dataItem._id}>
                    <td className="cd-code">
                      {dataItem.coordinator?.code == null || undefined
                        ? "No"
                        : dataItem.coordinator.code}
                    </td>
                    <td className="cd-name">
                      {`${dataItem.year}/${dataItem.month}/${dataItem.day}`}{" "}
                      {/* Modified date format */}
                    </td>
                    <td className="cd-name">{dataItem.name}</td>
                    {/* <td className="cd-address">{dataItem.mailingAddress}</td> */}
                    <td className="text-lowercase cd-email">
                      {dataItem.email}
                    </td>
                    <td className="cd-country">{dataItem.country}</td>

                    <td
                      colSpan="4"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <NavLink
                        to={`/sponsor/order/${dataItem._id}`}
                        className="sponsor-action-box"
                      >
                        <i className="fa-solid fa-box-open"></i>
                      </NavLink>

                      <NavLink
                        to={`/spform/selectpuja/${dataItem._id}`}
                        className="sponsor-action-box"
                      >
                        <i className="fa-solid fa-cart-plus"></i>
                      </NavLink>
                      <SponsorDeleteComponent id={dataItem._id} />
                    </td>
                  </tr>
                ))}
                </tbody>
          </table>
        </div>
        {/* no data found  */}
        {getAllSponsor.data?.data.length === 0 ? (
        
              <div
                className="sponsordetail-no-data-found-message"
              >
                <h1>
                  No Data Found <span>{search}</span>
              </h1>
            </div>
        ) : (<></>)
        }
         {/* Pagination Controls */}
         <div className="pagiControl">
                  <button className="btn btn-outline-primary"
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage((prev) => prev - 1)}
                  >
                    Prev
                  </button>

                  <span> Page {currentPage} of {totalPages} </span>

          <button
            className="btn btn-outline-primary"
                    disabled={currentPage === totalPages}
                    onClick={() => setCurrentPage((prev) => prev + 1)}
                  >
                    Next
                  </button>
        </div>
      </div>

      <div className="cd-absolute" style={{ display: show ? "block" : "none" }}>
        <div className="float-end">
          <i className="fa-solid fa-x" onClick={xbtn}></i>
        </div>
        <h2>Sponser Detail</h2>

        <div className="sp-list-grid">
          <div className="sp-type">
            <div className="text-capitalize">name </div>
          </div>
          <div>
            <div className="text-capitalize">:sangey</div>
          </div>
        </div>
        <div className="sp-list-grid">
          <div className="sp-type">
            <div className="text-capitalize">Coordinate </div>
          </div>
          <div>
            <div className="text-capitalize">: yes</div>
          </div>
        </div>
        <div className="sp-list-grid">
          <div className="sp-type">
            <div className="text-capitalize">Co.Code</div>
          </div>
          <div>
            <div className="text-capitalize">: 34352</div>
          </div>
        </div>
        <div className="sp-list-grid">
          <div className="sp-type">
            <div className="text-capitalize">Mailing Address </div>
          </div>
          <div>
            <div className="text-capitalize">: swoyambhu,ktm</div>
          </div>
        </div>
        <div className="sp-list-grid">
          <div className="sp-type">
            <div className="text-capitalize">Country </div>
          </div>
          <div>
            <div className="text-capitalize">: nepal</div>
          </div>
        </div>
        <div className="sp-list-grid">
          <div className="sp-type">
            <div className="text-capitalize">Email </div>
          </div>
          <div>
            <div className="text-capitalize">: sangeylama1000@gmail.com</div>
          </div>
        </div>
        <div className="sp-list-grid">
          <div className="sp-type">
            <div className="text-capitalize">whatsapp </div>
          </div>
          <div>
            <div className="text-capitalize">: +2843879873</div>
          </div>
        </div>
        <div className="sp-list-grid">
          <div className="sp-type">
            <div className="text-capitalize">weChat </div>
          </div>
          <div>
            <div className="text-capitalize">: +2843879873</div>
          </div>
        </div>
        <div className="sp-list-grid">
          <div className="sp-type">
            <div className="text-capitalize">others </div>
          </div>
          <div>
            <div className="text-capitalize">: ..</div>
          </div>
        </div>
      </div>

      {/* PAGINATION  SECTION */}
       
      {/* <PaginateComponent
        setPage={setPage}
        totalPages={totalPages}
        pageIndex={page}
      /> */}
      {/* ---------------------------------------------------------- */}

      {/* TOAST CONTAINER */}
      <ToastContainer />
    </>
  );
}
